@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.login {
  background: rgba(0, 0, 0, 0.41);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 1870px 1448px;
  position: absolute;
}

.login__container {
  box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
  width: 390px;
  height: 495px;
  background: #FFFFFF;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.loginTopic {
  font-family: 'Montserrat';
font-style: normal;
font-weight: bold;
font-size: 25.4086px;
line-height: 31px;
color: #000000;
margin-top: 20px;
margin-bottom: 20px;
}
.login__container > form {
  padding: 20px;
}
.inputText {
  color: #000000;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;  
}

.login__container > form > input,
.bx > input {
  padding-left: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 271px;
  height: 41px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px; 
  background: #E5E5E5;
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.1);
}

.para1{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  text-decoration: none;
  color: #000000;
  text-align: left;
}

.checkboxContainer {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}

.checkboxContainer input {
  width: 9px;
  height: 9px;
  border: 0.5px solid #868686;
  box-sizing: border-box;
  border-radius: 1px;
}

.login__container > form > button {
  width: 271px;
  height: 34px;
  border: 1px solid #830000;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 6px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #830000;
}

.paragraph {
  display: flex;
  flex-direction:row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.para2{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 10.6917px;
  line-height: 13px;
  color: #000000;
  text-align: center;
  text-decoration: none;
}

.para3{
  cursor: pointer;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 10.6917px;
  line-height: 13px;
  color: maroon;
  text-decoration: none;
}

i {
  position: absolute;
}

.loginRespectLogoRed {
  width: 68px;
  height: 27px;
}

.login_close {
  /* margin-left: 310px;
  font-size: 30px;
  margin-top: -15px; */
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
}
@media only screen and (min-width: 280px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 250px;
    height: 57vh;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 10px;
  }
  .login__container > form {
    padding: 10px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 1;
    width: 180px;
    height: 28px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 8px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 180px;
    height: 28px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
    cursor: pointer;
    
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  i {
    position: absolute;
    margin-top: -15px;
    margin-right: 10px;
  }
  i >.svg-inline--fa{
    font-size: 10px;
  }
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    font-size: 20px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 320px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 300px;
    height: 440px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 240px;
    height: 38px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 8px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 240px;
    height: 32px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  i >.svg-inline--fa{
    font-size: 12px;
  }
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    font-size: 20px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 375px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 340px;
    height: 480px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 270px;
    height: 41px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 8px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 270px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    font-size: 20px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 425px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 380px;
    height: 505px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 290px;
    height: 42px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 8px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 290px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    font-size: 25px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }
}
@media only screen and (min-width: 768px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 460px;
    height: 565px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 320px;
    height: 42px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 20px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 320px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
   font-size: 25px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 460px;
    height: 565px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 320px;
    height: 42px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 20px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 320px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
   font-size: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1200px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 460px;
    height: 565px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 320px;
    height: 42px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 20px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 320px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    font-size: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }
}
.homeBodyBottom {
  display: flex;
  flex: 1 1;
  height: 60vh;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px;
  justify-content: space-between;
}

.bodyContentRow {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  justify-content: space-between;
}
.color__block {
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: #830000;
  height: 109px;
}
.color__block > span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.015em;
  color: #ffffff;
}
.btn__reg {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  width: 123px;
  height: 41px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  background-color: #830000;
  color: #ffffff;
}
@media only screen and (min-width: 280px) {
  .homeBodyBottom {
    display: flex;
    flex: 1 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 10px 0px 15px;
    justify-content: space-between;
  }
  .color__block {
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}
@media only screen and (min-width: 320px) {
  .homeBodyBottom {
    display: flex;
    flex: 1 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 10px 0px 25px;
    justify-content: space-between;
  }
  .color__block {
    /* display: none; */
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}

@media only screen and (min-width: 375px) {
  .homeBodyBottom {
    display: flex;
    flex: 1 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 0px 25px;
    justify-content: space-between;
  }
  .color__block {
    /* display: none; */
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}

@media only screen and (min-width: 425px) {
  .homeBodyBottom {
    display: flex;
    flex: 1 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 10px 0px 25px;
    justify-content: space-between;
  }
  .color__block {
    /* display: none; */
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}

@media only screen and (min-width: 768px) {
  .homeBodyBottom {
    display: flex;
    flex: 1 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 26px;
    justify-content: space-between;
  }
  .color__block {
    /* display: none; */
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}

@media only screen and (min-width: 1024px) {
  .homeBodyBottom {
    display: flex;
    flex: 1 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 30px;
    justify-content: space-between;
  }
  .color__block {
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: #830000;
    height: 98px;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}
@media only screen and (min-width: 1200px) {
  .homeBodyBottom {
    display: flex;
    flex: 1 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 30px;
    justify-content: space-between;
  }
  .color__block {
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: #830000;
    height: 109px;
    flex: 0.5 1;
    padding: 25px;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 41px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}

.body_content {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
}

.headingBodyContent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  width: auto;
  text-decoration: underline;
  /* text-transform: capitalize; */
}

.contentOfBodyContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
  flex: 1 1;
}

.content_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  flex: 1 1;
  padding: 0px 0px;
  text-align: justify;
}

.content_image {
  width: 233px;
  height: 176px;
  display: block;
}

.topicBodyContent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 12pt;
  line-height: 16px;
  letter-spacing: -0.015em;
  margin-left: 0px;
}

.buttonLearnMore {
  background: #ffffff;
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  width: 130px;
  height: 40px;
  border: 1px solid #555;
  cursor: pointer;
  width: 120px;
  height: 40px;
  margin-top: 15px;
  margin-left: 0px;
  letter-spacing: -0.015em;
  color: #151617;
  margin-bottom: 20px;
}
@media only screen and (min-width: 280px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px;
    flex: 1 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    border: 1px solid #555;
    cursor: pointer;
    width: 70px;
    height: 20px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px;
    flex: 1 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    border: 1px solid #555;
    cursor: pointer;
    width: 70px;
    height: 20px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 375px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px;
    flex: 1 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 425px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px;
    flex: 1 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    padding: 0px 5px;
    flex: 1 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    width: 49%;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    width: auto;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding: 0px 5px;
    flex: 1 1;
  }
  .imgContainer__box {
    width: 203px;
    height: 176px;
    object-fit: cover;
    display: block;
    overflow: hidden;
  }
  .content_image {
    width: auto;
    height: 176px;
    display: block;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    width: 49%;
    justify-content: space-between;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    width: auto;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding: 0px 5px;
    flex: 1 1;
  }
  .imgContainer__box {
    width: 203px;
    height: 176px;
    object-fit: cover;
    display: block;
    overflow: hidden;
  }
  .content_image {
    width: auto;
    height: 176px;
    display: block;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}

.bodyLeftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 30px 0px 0px 0px;
}
.topicAndImgLeftContainer {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
}
.topicLeftContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 5px 0px;
}
.topicLeftContainer > span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
}
.img__box {
  height: 230px;
  overflow: hidden;
}
.image_leftContainer {
  width: 230px;
  height: 230px;
}
.imageContainer {
  display: flex;
  flex-direction: row;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.015em;
  width: auto;
}

.textContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1 1;
}
.textContainer:hover {
  cursor: pointer;
  color: #c4c4c4;
}
.left_containerHeading {
  padding-top: 5px;
}
.left_containerHeading > span {
  font-family: "";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-decoration: underline;

}
.left_containerContent {
  padding: 10px 5px 0px 0px;
  text-align: justify;
}
.left_containerContent > span {
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.015em;
}
@media only screen and (min-width: 280px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px 10px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5 1;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 85%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 310;
    font-size: 13px;
  }
  .img__box {
    height: 200px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: 100%;
    object-fit: cover;
    height: 240px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 1 1;
    width: 100%;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 10px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12.5px;
    line-height: 9px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 320px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px 10px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5 1;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 85%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 310;
    font-size: 13px;
  }
  .img__box {
    height: 200px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: 100%;
    object-fit: cover;
    height: 240px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 1 1;
    width: 100%;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    /* text-transform: capitalize; */
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 10px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12.5px;
    line-height: 9px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 375px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 20px 10px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5 1;
    flex-direction: column;
    width: 172px;
    overflow: hidden;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    flex: 0.5 1;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 310;
    font-size: 13px;
  }
  .img__box {
    height: 300px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 0.5 1;
    width: 100%;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    /* text-transform: capitalize; */
    text-decoration: underline;
    

  }
  .left_containerContent {
    padding: 10px 5px 10px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 9px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 425px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 20px 20px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5 1;
    flex-direction: column;
    width: 172px;
    overflow: hidden;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 300px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 0.6 1;
    width: 100%;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;
    

  }
  .left_containerContent {
    padding: 10px 5px 20px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 540px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 20px 20px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5 1;
    flex-direction: column;
    width: 172px;
    overflow: hidden;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 230px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: auto;
    object-fit: cover;
    height: 230px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 0.6 1;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 20px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 768px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 20px 20px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5 1;
    flex-direction: column;
    width: 172px;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 210px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: auto;
    object-fit: cover;
    height: 210px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 30px 0px;
    flex: 1 1;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 30px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.015em;
  }
}

@media only screen and (min-width: 1024px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 35px 0px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5 1;
    flex-direction: column;
    width: 182px;
    overflow: hidden;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 270px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: auto;
    object-fit: cover;
    height: 210px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1 1;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 0px 0px;
    text-align: justify;
    border-bottom: none;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 1200px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 30px 0px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5 1;
    flex-direction: column;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 225px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: auto;
    height: 225px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1 1;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;
  }
  .left_containerContent {
    padding: 10px 5px 0px 0px;
    text-align: justify;
    border-bottom: none;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
  }
}

.left_container {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.hr_lineLeft {
  width: auto;
  display: none;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  border: 1px solid #e6e6e6;
  margin-bottom: -20px;
}

.home_bodyLeft {
  display: flex;
  flex: 0.47 1;
  flex-direction: column;
}

.BodyContent_margin {
  display: block;
  margin-right: 20px;
  padding-top: 20px;
}
@media only screen and (min-width: 280px) {
  .home_bodyLeft {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;

  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 320px) {
  .home_bodyLeft {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 375px) {
  .home_bodyLeft {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 425px) {
  .home_bodyLeft {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 678px) {
  .home_bodyLeft {
    display: flex;
    flex: 1 1;
    flex-direction: column;
  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .home_bodyLeft {
    display: flex;
    flex: 0.47 1;
    flex-direction: column;
  }

  .BodyContent_margin {
    display: block;
    margin-right: 20px;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .home_bodyLeft {
    display: flex;
    flex: 0.46 1;
    flex-direction: column;
    /* align-items: flex-start; */
  }

  .BodyContent_margin {
    display: block;
    margin-right: 20px;
    padding-top: 20px;
    flex:1 1;
    height: 40vh;
    overflow: auto;
  }
}

.home_bodyRight {
  display: flex;
  flex: 0.53 1;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
}
.bodyContentRow_heading {
  display: block;
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 0px 0px 30px;
}
.bodyContentRow_heading > span {
  display: block;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  text-align: left;
  line-height: 1.5;
}
@media only screen and (min-width: 280px) {
  .home_bodyRight {
    display: flex;
    flex: 1 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}
@media only screen and (min-width: 320px) {
  .home_bodyRight {
    display: flex;
    flex: 1 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}
@media only screen and (min-width: 375px) {
  .home_bodyRight {
    display: flex;
    flex: 1 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}
@media only screen and (min-width: 425px) {
  .home_bodyRight {
    display: flex;
    flex: 1 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}
@media only screen and (min-width: 768px) {
  .home_bodyRight {
    display: flex;
    flex: 1 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}

@media only screen and (min-width: 1024px) {
  .home_bodyRight {
    display: flex;
    flex: 0.53 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
  .bodyContentRow_heading {
    display: block;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 30px;
  }
  .bodyContentRow_heading > span {
    display: block;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}

@media only screen and (min-width: 1200px) {
  .home_bodyRight {
    display: flex;
    flex: 0.54 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
  .bodyContentRow_heading {
    display: block;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 30px;
  }
  .bodyContentRow_heading > span {
    display: block;
    font-family:arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    text-align: left;
    line-height: 1.5;
  }
}

.homeBodyRightContainer {
  width: 100%;
  padding: 30px;
  cursor: pointer;
}
.topic1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 5px 0px;
}
.topic1 > span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
}

.right_container1 {
  width: 100%;
  height: auto;
  background-color: none;
  border: 1px solid #555;
  font-size: 28px;
  text-align: justify;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
}
.home_image1 {
  width: 100%;
  height: 491.64px;
}
.texts {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 10px;
}
.texts:hover {
  cursor: pointer;
  color: #c4c4c4;
}
.right_container1Heading {
  font-size: 26px;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: bold;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
  font-style: normal;
  letter-spacing: -0.015em;
  line-height: 33px;
  width: 100%;
}
.right_container1Content {
  display: flex;
  flex-direction: column;
  border-bottom: none;
}

.right_container1Content > span {
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.6;
  text-align: justify;
  letter-spacing: -0.015em;
  color: #000000;
  padding: 10px 0px;
}
@media only screen and (min-width: 280px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 10px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 30px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 25px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 18px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 18px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.6;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 320px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 10px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 30px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 25px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 18px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 18px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.6;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 375px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 10px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 30px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 25px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 18px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 18px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 425px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 10px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 40px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 40px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 18px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 18px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 768px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 20px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 60px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 491.64px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 50px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 26px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 33px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 1024px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 20px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 0px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: 1px solid #555;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 491.64px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px;
  }
  .right_container1Heading {
    font-size: 26px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 33px;
    width: 100%;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 1200px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 30px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 0px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: 1px solid #555;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 491.64px;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px;
  }
  .right_container1Heading {
    font-size: 26px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 33px;
    width: 100%;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
}
.body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 0.5 1;
}
@media only screen and (min-width: 320px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
  }
}
@media only screen and (min-width: 375px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
  }
}
@media only screen and (min-width: 425px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
  }
}
@media only screen and (min-width: 768px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80vh;
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
  }
}
@media only screen and (min-width: 1024px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0.5 1;
  }
}
@media only screen and (min-width: 1200px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0.5 1;

  }
}

.headerContainer {
  height: 60px;
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: #e76d5e;
  color: white;
}

.header {
  height: 60px;
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
}

.header_menu {
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
}

.header_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: -5px;
  display: block;
}
.header_text:hover {
  cursor: pointer;
  color: rgb(181, 182, 184);
}

.header_menuText {
  margin-left: -70px;
  color: rgb(250, 251, 253);
}

.header_search {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.header_searchIcon {
  width: 23px;
  height: 23px;
  background-color: none;
}

.header_searchInput {
  width: 198px;
  height: 28px;
  background: white;
  border: none;
  border-radius: 14px;
  margin-left: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  text-align: center;
  display: block;
}

.headerLast {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 230px;
}
.sep {
  display: block;
  color: white;
}

.header_login {
  text-decoration: none;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.header_register {
  text-decoration: none;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  text-align: center;
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: white;
  padding-right: 0px;
}

.header_logo {
  margin-right: 50px;
}

.header_logo > .accountCircleIcon {
  width: 30px;
  height: 30px;
  margin-right: 0px;
  margin-top: 0px;
}
.searchAreaView {
}
.searchResultShow {
  width: 600px;
  background-color: azure;
  color: black;
  position: absolute;
  margin-top: 10px;
  max-height: 400px;
  overflow-y: hidden;
  padding: 10px 5px;
}
.searchResultShow_01 {
  margin-bottom: 5px;
  padding: 2px;
}
.searchResultShow_01:hover {
  cursor: pointer;
}
@media only screen and (min-width: 280px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: auto;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: none;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-start;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: none;
  }
  .header_searchIcon3 {
    display: block;
  }

  .header_searchInput {
    width: 138px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    /* display: none; */
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  .sep {
    display: none;
    padding-left: 5px;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: none;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: none;
    padding-right: 20px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 150px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 320px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: auto;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: none;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-start;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
    display: none;
  }
  .header_searchIcon0 {
    display: none;
  }

  .header_searchIcon3 {
    display: block;
  }
  .header_searchInput {
    width: 158px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    /* display: none; */
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  .sep {
    display: none;
    padding-left: 5px;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: none;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: none;
    padding-right: 20px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 180px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 375px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: auto;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: none;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-start;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: none;
  }
  .header_searchIcon3 {
    display: block;
  }
  .header_searchInput {
    width: 178px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    /* display: none; */
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  .sep {
    display: none;
    padding-left: 5px;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: none;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: none;
    padding-right: 10px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 250px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 425px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: auto;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: none;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-start;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
    display: none;
  }
  .header_searchIcon0 {
    display: none;
  }
  .header_searchIcon3 {
    display: block;
  }
  .header_searchInput {
    width: 198px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    /* display: none; */
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  .sep {
    display: none;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: none;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: none;
    padding-right: 20px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 300px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 768px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: 100px;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: block;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1 1;
    align-items: center;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: block;
  }
  .header_searchIcon3 {
    display: none;
  }
  .header_searchInput {
    width: 198px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    display: block;
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* width: 230px; */
    width: auto;
    padding-right: 20px;
  }
  .sep {
    display: block;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: block;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: block;
    padding-right: 0px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 600px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: 100px;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: block;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1 1;
    align-items: center;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: block;
  }
  .header_searchIcon3 {
    display: none;
  }

  .header_searchInput {
    width: 198px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    display: block;
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* width: 230px; */
    width: auto;
    padding-right: 25px;
  }
  .sep {
    display: block;
    padding-left: 10px;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: block;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: block;
    padding-right: 0px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
}
@media only screen and (min-width: 1200px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    flex: 1 1;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: 100px;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: block;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1 1;
    align-items: center;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: block;
  }
  .header_searchIcon3 {
    display: none;
  }
  .header_searchInput {
    width: 198px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    display: block;
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding-right: 25px;
  }
  .sep {
    display: block;
    padding-left: 10px;
  }
  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: block;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: block;
    padding-right: 0px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
}


.Navbar {
  width: auto;
}

.home_container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #71888e;
  width: 100%;
  height: 304px;
}
.home_log {
  width: 365px;
  height: 145px;
  object-fit: contain;

}

.home_hContent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
  padding: 20px;
}
.content_h {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  padding-left: 50px;
  width: 100%;
}

.home_date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
}
@media only screen and (min-width:280px) {
  .Navbar {
      width: auto;
    }
    
    .home_container {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #71888e;
      width: 100%;
      height: 250px;
    }
    .home_log {
      width: 190px;
      height: 60px;
      object-fit: contain;

    }
    
    .home_hContent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
      padding: 10px 20px;
    }
    .content_h {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      padding-left: 20px;
      padding-top: 40px;
      width: 100%;
    }
    
    .home_date {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
    }
}
@media only screen and (min-width:320px) {
  .Navbar {
      width: auto;
    }
    
    .home_container {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #71888e;
      width: 100%;
      height: 250px;
    }
    .home_log {
      width: 190px;
      height: 60px;
      object-fit: contain;

    }
    
    .home_hContent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
      padding: 10px 20px;
    }
    .content_h {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      padding-left: 20px;
      padding-top: 40px;
      width: 100%;
    }
    
    .home_date {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
    }
}
@media only screen and (min-width:375px) {
  .Navbar {
      width: auto;
    }
    
    .home_container {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #71888e;
      width: 100%;
      height: 260px;
    }
    .home_log {
      width: 230px;
      height: 80px;
      object-fit: contain;

    }
    
    .home_hContent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
      padding: 20px 20px;
    }
    .content_h {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      padding-left: 20px;
      padding-top: 20px;
      width: 100%;
    }
    
    .home_date {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
    }
}
@media only screen and (min-width:425px) {
  .Navbar {
      width: auto;
    }
    
    .home_container {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #71888e;
      width: 100%;
      height: 270px;
    }
    .home_log {
      width: 230px;
      height: 80px;
      object-fit: contain;

    }
    
    .home_hContent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
      padding: 20px;
    }
    .content_h {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      padding-left: 20px;
      padding-top: 30px;
      width: 100%;
    }
    
    .home_date {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
    }
}
@media only screen and (min-width:768px) {
    .Navbar {
        width: auto;
      }
      
      .home_container {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #71888e;
        width: 100%;
        height: 290px;
      }
      .home_log {
        width: 305px;
        height: 115px;
        object-fit: contain;

      }
      
      .home_hContent {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
        padding: 20px;
      }
      .content_h {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        vertical-align: middle;
        padding-left: 20px;
        padding-top: 20px;
        width: 100%;
      }
      
      .home_date {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
      }
}
@media only screen and (min-width:1024px) {
    .Navbar {
        width: auto;
      }
      
      .home_container {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #71888e;
        width: 100%;
        height: 304px;
      }
      .home_log {
        width: 355px;
        height: 135px;
        object-fit: contain;

      }

      
      .home_hContent {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
        padding: 20px;
      }
      .content_h {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        vertical-align: middle;
        padding-left: 20px;
        padding-top: 20px;
        width: 100%;
      }
      
      .home_date {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
      }
}
@media only screen and (min-width:1200px) {
    .Navbar {
        width: auto;
      }
      
      .home_container {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #71888e;
        width: 100%;
        height: 304px;
      }
      .home_log {
        width: 365px;
        height: 145px;
        object-fit: contain;
      }
      
      .home_hContent {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
        padding: 20px;
      }
      .content_h {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        vertical-align: middle;
        padding-left: 20px;
        width: 100%;
      }
      
      .home_date {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
      }
}
/*

{
a:nth-child(2):hover ~ .line {
    transform: translate(126px);
}

a:nth-child(3):hover ~ .line {
    transform: translate(252px);
}

a:nth-child(4):hover ~ .line {
    transform: translate(378px);
}

a:nth-child(5):hover ~ .line {
    transform: translate(504px);
}

a:nth-child(6):hover ~ .line {
    transform: translate(630px);
}

a:nth-child(7):hover ~ .line {
    transform: translate(756px);
}

a:nth-child(8):hover ~ .line {
    transform: translate(882px);
}

a:nth-child(9):hover ~ .line {
    transform: translate(1008px);
}

a:nth-child(10):hover ~ .line {
    transform: translate(1134px);
}

a:nth-child(11):hover ~ .line {
    transform: translate(1260px);
}

a:nth-child(12):hover ~ .line {
    transform: translate(1386px);
}

a:nth-child(13):hover ~ .line {
    transform: translate(1512px);
}

a:nth-child(14):hover ~ .line {
    transform: translate(1680px);
}}*/

.homeFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 0px;
}

.footer1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  height: 200px;
  width: 100%;
  padding: 0px 50px;
  background-color: #e0e0e0;
}

.respectLogoRed {
  width: 222px;
  height: 91px;
}

.footer {
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}
.footer_content1 > span,
.footer_content2 > span {
  padding-bottom: 10px;
  font-weight: 900;
}
.footer_content1 {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
  padding-bottom: 0px;
}

.footer_content2 {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
  padding-bottom: 0px;
}

.footer_content3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: auto;
}

.button8 {
  background: maroon;
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  width: 130px;
  height: 40px;
  border: none;
  cursor: pointer;
  color: white;
}

.button9 {
  background: white;
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  width: 130px;
  height: 40px;
  border: none;
  cursor: pointer;
  color: black;
  margin-top: 20px;
  border: 1px solid #555;
}

.footer_last {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}

.footer_last1 {
  display: flex;
  flex-direction: row;
  color: black;
  font-size: 20px;
  margin-left: 40px;
  width: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
}

.footer_last {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.footer_last1,
.footer_last2 {
  width: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
}
.footer_link {
  text-decoration: none;
  color: black;
}
.smedia_icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0px;
}
.smedia_icon_1 {
  margin-right: 5px;
}
.contact_us {
  /* font-weight: bold; */
  margin: 10px 0px;
}
@media only screen and (min-width: 280px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding: 20px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 160px;
    height: 58px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-around;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
    align-items: center;
  }
  .smedia_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: center;
  }

  .footer_content3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    vertical-align: middle;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 90px;
    height: 35px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 90px;
    height: 35px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 0px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 0px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
}
@media only screen and (min-width: 320px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding: 20px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 160px;
    height: 58px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-around;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
    align-items: center;
  }
  .smedia_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: center;
  }

  .footer_content3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    vertical-align: middle;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 90px;
    height: 35px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 90px;
    height: 35px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 0px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 0px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
}
@media only screen and (min-width: 375px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding: 20px 50px 20px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 170px;
    height: 60px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-around;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
    align-items: center;
  }
  .smedia_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: center;
  }

  .footer_content3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    vertical-align: middle;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 110px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 110px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 0px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
}
@media only screen and (min-width: 425px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    width: 100%;
    padding: 15px 50px 0px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 180px;
    height: 70px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-around;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
    align-items: center;
  }
  .smedia_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: center;
  }

  .footer_content3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    vertical-align: middle;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 110px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 110px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 0px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
}
@media only screen and (min-width: 768px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    height: 160px;
    width: 100%;
    padding: 0px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 190px;
    height: 80px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    width: 52%;
    justify-content: space-between;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
    align-items: flex-start;
  }

  .footer_content3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: auto;
    width: auto;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 120px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 120px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 5px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
  .smedia_icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: left;
  }
}
@media only screen and (min-width: 1024px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    height: 180px;
    width: 100%;
    padding: 0px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 220px;
    height: 90px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    width: 42%;
    justify-content: space-between;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 5px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
    align-items: flex-start;
  }

  .footer_content3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: auto;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 125px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 125px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 10px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
  .smedia_icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: left;
  }
}
@media only screen and (min-width: 1200px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    height: 200px;
    width: 100%;
    padding: 0px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 222px;
    height: 91px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-between;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 5px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
    align-items: flex-start;
  }

  .footer_content3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: auto;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 10px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
  .smedia_icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: left;
  }
}

.categoryBody{
    display: flex;
    flex-direction: column;
    width: auto;
}

iframe{
  display: none !important;
}

.load {
  z-index: 2;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  box-shadow: #e76d5e 1.5em 0 0 0, #e76d5e 1.5em 0 0 0,
    #e76d5e -1.1em -1.1em 0 0, rgb(241, 239, 239) -1.1em 1.1em 0 0,
    rgb(241, 239, 239) -1.1em 1.1em 0 0, rgb(241, 239, 239) -1.1em -1.1em 0 0,
    #e76d5e 0 -1.5em 0 0, #e76d5e 1.1em -1.1em 0 0;
}

/* Animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.upperTextContainer {
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: flex-start;
}

.containerText,
.containerText1 {
  display: flex;
  flex-direction: column;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 11.9912px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: -0.015em;
  color: #000000;
  width: auto;
}
@media only screen and (min-width: 320px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
  }
}
@media only screen and (min-width: 375px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
  }
}
@media only screen and (min-width: 425px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
  }
}
@media only screen and (min-width: 768px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    flex: 1 1;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1200px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    flex: 1 1;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    /* line-height: 18px; */
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
    line-height: 1.5;
  }
}

.categoryUpper {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 30px;
}

.mainUpperContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.categoryUp {
  display: flex;
  flex-direction: column;
}

.categoryLine {
  width: auto;
  height: 4px;
  background-color: maroon;
}

.categoryHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 29.6px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
}

.categoryText {
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #404040;
}

.categoryAddition select {
  width: 210px;
  padding: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #c4c4c4;
  border: none;
  height: 38px;
}

.categoryAddition {
  width: 230px;
  height: 38px;
  background: #e0e0e0;
  border-radius: 5px;
  border: none;
}

.imageCContainer {
  display: flex;
  flex-direction: column;
  width: auto;
  overflow: hidden;
}

.categoryImage1 {
  width: auto;
  height: 875px;
  padding: 20px 0px;
}

.upperContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8vh;
}

.upperHeading {
  font-family: "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22.1377px;
  line-height: 33px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
  width: 750px;
  /* text-transform: capitalize; */
}

.topicUpperContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #454545;
}

.dateUpperContainer {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #454545;
  padding-bottom: 10px;
}
.box {
  border: 1px solid #c4c4c4;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.UCParagaraph {
  display: flex;
  flex-direction: row;
  width: auto;
}

.upperContainerButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.buttonReadMore {
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 11.9912px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: -0.015em;
  color: #151617;
  width: 136px;
  height: 30px;
  justify-content: center;
  background-color: white;
  align-items: center;
}
.buttonReadMore:hover {
  cursor: pointer;
  color: #c4c4c4;
  border-color: #c4c4c4;
}
@media only screen and (min-width: 320px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px 30px 10px 30px;
  }

  .mainUpperContainer {
    flex-wrap: wrap;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
    padding: 10px 0px 0px;
  }

  .categoryAddition select {
    width: 180px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 28px;
  }

  .categoryAddition {
    width: 180px;
    height: 28px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 220px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 250px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 120px;
    height: 30px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 375px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px 30px 10px 30px;
  }

  .mainUpperContainer {
    flex-wrap: wrap;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
    padding: 10px 0px;
  }

  .categoryAddition select {
    width: 180px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 28px;
  }

  .categoryAddition {
    width: 180px;
    height: 28px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 260px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 320px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 100px;
    height: 30px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 425px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px 30px 10px 30px;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
  }

  .categoryAddition select {
    width: 180px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 38px;
  }

  .categoryAddition {
    width: 180px;
    height: 38px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 280px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 320px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 120px;
    height: 35px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 768px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
  }

  .categoryAddition select {
    width: 210px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 38px;
  }

  .categoryAddition {
    width: 230px;
    height: 38px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 480px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 650px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 130px;
    height: 37px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 1024px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 29.6px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
  }

  .categoryAddition select {
    width: 210px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 38px;
  }

  .categoryAddition {
    width: 230px;
    height: 38px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 690px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22.1377px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 750px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 134px;
    height: 35px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 1200px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 29.6px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
  }

  .categoryAddition select {
    width: 210px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 38px;
  }

  .categoryAddition {
    width: 230px;
    height: 38px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 875px;
    object-fit: fill;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 850px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 140px;
    height: 41px;
    justify-content: center;
    background-color: white;
    align-items: center;
  }
  .pageNotFound {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px 0;
    font-style: italic;
    color:#706f6f
    
  }
}

.atclcontainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: white;
}
.imgAndText {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.imageDiv {
  overflow: hidden;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: solid;
}

.gradient {

  z-index: 1;
  width: 100%;
  height: 150px;
}
.containerAd__para {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  padding-bottom: 10px;
}
.container_para {
  flex: 1 1;
  /* padding-right: 30px; */
}
.ad_div {
  display: flex;
  flex: 0.17 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 65px 10px 40px 0px;
  display: block;
}
.ads {
  width: 90%;
  height: 100%;
  background: #c4c4c4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.uploaded__Ad {
  width: 100%;
  background: #c4c4c4;
  display: flex;
  object-fit: cover;
}
.ads > input {
  visibility: hidden;
  width: 0;
  height: 0;
  cursor: pointer;
}
.ads > i {
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 29.6111px;
  line-height: 44px;
  letter-spacing: -0.015em;
  color: #000000;
}
.para {
  font-family: "arial, sans-serif";
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: -0.015em;
  /* padding: 10px 0px 30px 30px; */
  color: #000000;
}
.para_heading {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  margin-top: 20px;
  color: #454545;
  padding-left: 30px;
}
.para_date {
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  padding-left: 30px;
  color: #454545;
  /* text-transform: capitalize; */
}
.image_text {
  font-style: normal;
  font-family: "arial, sans-serif";
  font-weight: bold;
  font-size: 36.1377px;
  line-height: 54px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color:black;
  position: absolute;
  width: 100%;
  padding: 30px;
  /* z-index: 1; */
}

.image_2nd {
  width: 100%;
  height: 600px;
  object-fit: contain;
}
.image_2ndDiv {
  overflow: hidden;
  width: auto;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
}
.horizontal_container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
  padding-bottom: 20px;
  overflow-x: scroll;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  z-index: 1;
}
.more_art {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
  padding-left: 0px;
  padding-top: 10px;
}
.hr_article {
  display: flex;
  flex-direction: row;
}
@media only screen and (min-width: 320px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }

  .gradient {
    
    z-index: 1;
    width: 100%;
    height: 60px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    padding-bottom: 10px;
  }
  .container_para {
    flex: 1 1;
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: none;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 20px; */
    color: #000000;
  }
  .para_heading {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 20px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 20px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 100%;
    padding: 20px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 180px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
    padding-left: 20px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 8px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 375px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }

  .gradient {
    z-index: 1;
    width: 100%;
    height: 100px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    padding-bottom: 10px;
  }
  .container_para {
    flex: 1 1;
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: none;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }
  .para_heading {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 100%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 220px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
    padding-left: 30px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 8px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 425px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }
 
  .gradient {
   
    z-index: 1;
 
    width: 100%;
    height: 200px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    padding-bottom: 10px;
  }
  .container_para {
    flex: 1 1;
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: none;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }

  .para_heading {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 100%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 250px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
    padding-left: 30px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 8px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 768px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }
 
  .gradient {
   
    z-index: 1;
 
    width: 100%;
    height: 150px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    padding-bottom: 10px;
  }
  .container_para {
    /* flex: 0.83; */
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: block;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }
  .para_heading {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 34px;
    line-height: 44px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    position: absolute;
    color:black;
    width: 100%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 390px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 10px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1024px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }
 
  .gradient {
   
    z-index: 1;
 
    width: 100%;
    height: 150px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    padding-bottom: 10px;
  }
  .container_para {
    /* flex: 0.83; */
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: block;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }
  .para_heading {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 35px;
    line-height: 54px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 95%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 500px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 10px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1200px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }
 

  .gradient {
   
    z-index: 1;
 
    width: 100%;
    height: 150px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    padding-bottom: 10px;
  }
  .container_para {
    flex: 1 1;
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: block;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }

  .para_heading {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 36.1377px;
    line-height: 54px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 100%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 75%;
    height: 600px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 10px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.categoryNavbar {
  width: 100%;
}

.categoryBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 5vh;
  vertical-align: middle;
  flex: 1 1;
  width: 100%;
  border-top: 4px solid #c4c4c4;
  background-color: #e0e0e0;
  overflow: auto;
  padding: 10px;

}
.link {
  text-decoration: none;
}
.link__span {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
}
.link__span:hover {
  cursor: pointer;
  color: #726c6c;
}
@media only screen and (min-width: 280px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 320px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 375px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 425px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 768px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 1200px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }
  .link__span {
    text-decoration: none;
    font-weight: bold;
    width: auto;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }
}



:root {
  --mont: "Montserrat", serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --mont: "Montserrat", serif;
}

#root .font-mont {
  font-family: var(--mont);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

