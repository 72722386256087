.homeBodyRightContainer {
  width: 100%;
  padding: 30px;
  cursor: pointer;
}
.topic1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 5px 0px;
}
.topic1 > span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
}

.right_container1 {
  width: 100%;
  height: auto;
  background-color: none;
  border: 1px solid #555;
  font-size: 28px;
  text-align: justify;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
}
.home_image1 {
  width: 100%;
  height: 491.64px;
}
.texts {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 10px;
}
.texts:hover {
  cursor: pointer;
  color: #c4c4c4;
}
.right_container1Heading {
  font-size: 26px;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: bold;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
  font-style: normal;
  letter-spacing: -0.015em;
  line-height: 33px;
  width: 100%;
}
.right_container1Content {
  display: flex;
  flex-direction: column;
  border-bottom: none;
}

.right_container1Content > span {
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.6;
  text-align: justify;
  letter-spacing: -0.015em;
  color: #000000;
  padding: 10px 0px;
}
@media only screen and (min-width: 280px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 10px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 30px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 25px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 18px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 18px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.6;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 320px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 10px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 30px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 25px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 18px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 18px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.6;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 375px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 10px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 30px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 25px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 18px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 18px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 425px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 10px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 40px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 40px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 18px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 18px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 768px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 20px 0px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 60px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: none;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 491.64px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px 50px;
    text-align: justify;
  }
  .right_container1Heading {
    font-size: 26px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 33px;
    width: 100%;
    text-transform: uppercase;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e6e6e6;
    text-align: justify;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 1024px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 20px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 0px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: 1px solid #555;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 491.64px;
    object-fit: cover;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px;
  }
  .right_container1Heading {
    font-size: 26px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 33px;
    width: 100%;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 1200px) {
  .homeBodyRightContainer {
    width: 100%;
    padding: 30px;
    cursor: pointer;
  }
  .topic1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: 5px 0px;
  }
  .topic1 > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }

  .right_container1 {
    width: 100%;
    height: auto;
    background-color: none;
    border: 1px solid #555;
    font-size: 28px;
    text-align: justify;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
  }
  .home_image1 {
    width: 100%;
    height: 491.64px;
  }
  .texts {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 10px;
  }
  .right_container1Heading {
    font-size: 26px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    font-style: normal;
    letter-spacing: -0.015em;
    line-height: 33px;
    width: 100%;
  }
  .right_container1Content {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }

  .right_container1Content > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    padding: 10px 0px;
  }
}
