.homeBodyBottom {
  display: flex;
  flex: 1;
  height: 60vh;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px;
  justify-content: space-between;
}

.bodyContentRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  justify-content: space-between;
}
.color__block {
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: #830000;
  height: 109px;
}
.color__block > span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.015em;
  color: #ffffff;
}
.btn__reg {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  width: 123px;
  height: 41px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.015em;
  background-color: #830000;
  color: #ffffff;
}
@media only screen and (min-width: 280px) {
  .homeBodyBottom {
    display: flex;
    flex: 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 10px 0px 15px;
    justify-content: space-between;
  }
  .color__block {
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}
@media only screen and (min-width: 320px) {
  .homeBodyBottom {
    display: flex;
    flex: 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 10px 0px 25px;
    justify-content: space-between;
  }
  .color__block {
    /* display: none; */
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}

@media only screen and (min-width: 375px) {
  .homeBodyBottom {
    display: flex;
    flex: 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 0px 25px;
    justify-content: space-between;
  }
  .color__block {
    /* display: none; */
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}

@media only screen and (min-width: 425px) {
  .homeBodyBottom {
    display: flex;
    flex: 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 10px 0px 25px;
    justify-content: space-between;
  }
  .color__block {
    /* display: none; */
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}

@media only screen and (min-width: 768px) {
  .homeBodyBottom {
    display: flex;
    flex: 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 26px;
    justify-content: space-between;
  }
  .color__block {
    /* display: none; */
    display: flex;
    flex-direction: column;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}

@media only screen and (min-width: 1024px) {
  .homeBodyBottom {
    display: flex;
    flex: 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 30px;
    justify-content: space-between;
  }
  .color__block {
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: #830000;
    height: 98px;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 39px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}
@media only screen and (min-width: 1200px) {
  .homeBodyBottom {
    display: flex;
    flex: 1;
    height: 60vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: space-between;
  }

  .bodyContentRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 30px;
    justify-content: space-between;
  }
  .color__block {
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: #830000;
    height: 109px;
    flex: 0.5;
    padding: 25px;
  }
  .color__block > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }
  .btn__reg {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    width: 123px;
    height: 41px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.015em;
    background-color: #830000;
    color: #ffffff;
  }
}
