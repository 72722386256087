* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.categoryNavbar {
  width: 100%;
}

.categoryBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 5vh;
  vertical-align: middle;
  flex: 1;
  width: 100%;
  border-top: 4px solid #c4c4c4;
  background-color: #e0e0e0;
  overflow: auto;
  padding: 10px;

}
.link {
  text-decoration: none;
}
.link__span {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
}
.link__span:hover {
  cursor: pointer;
  color: #726c6c;
}
@media only screen and (min-width: 280px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 320px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 375px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 425px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 768px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }

  .link__span {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-right: 20px;
    width: auto;
  }
}
@media only screen and (min-width: 1200px) {
  .categoryNavbar {
    width: 100%;
  }

  .categoryBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 5vh;
    vertical-align: middle;
    flex: 1;
    width: 100%;
    border-top: 4px solid #c4c4c4;
    background-color: #e0e0e0;
    overflow: auto;
    padding: 10px;
  }
  .link__span {
    text-decoration: none;
    font-weight: bold;
    width: auto;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }
}
