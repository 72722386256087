.headerContainer {
  height: 60px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: #e76d5e;
  color: white;
}

.header {
  height: 60px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
}

.header_menu {
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
}

.header_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: -5px;
  display: block;
}
.header_text:hover {
  cursor: pointer;
  color: rgb(181, 182, 184);
}

.header_menuText {
  margin-left: -70px;
  color: rgb(250, 251, 253);
}

.header_search {
  display: flex;
  flex: 1;
  align-items: center;
}

.header_searchIcon {
  width: 23px;
  height: 23px;
  background-color: none;
}

.header_searchInput {
  width: 198px;
  height: 28px;
  background: white;
  border: none;
  border-radius: 14px;
  margin-left: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  text-align: center;
  display: block;
}

.headerLast {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 230px;
}
.sep {
  display: block;
  color: white;
}

.header_login {
  text-decoration: none;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.header_register {
  text-decoration: none;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  text-align: center;
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: white;
  padding-right: 0px;
}

.header_logo {
  margin-right: 50px;
}

.header_logo > .accountCircleIcon {
  width: 30px;
  height: 30px;
  margin-right: 0px;
  margin-top: 0px;
}
.searchAreaView {
}
.searchResultShow {
  width: 600px;
  background-color: azure;
  color: black;
  position: absolute;
  margin-top: 10px;
  max-height: 400px;
  overflow-y: hidden;
  padding: 10px 5px;
}
.searchResultShow_01 {
  margin-bottom: 5px;
  padding: 2px;
}
.searchResultShow_01:hover {
  cursor: pointer;
}
@media only screen and (min-width: 280px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: auto;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: none;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: none;
  }
  .header_searchIcon3 {
    display: block;
  }

  .header_searchInput {
    width: 138px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    /* display: none; */
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  .sep {
    display: none;
    padding-left: 5px;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: none;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: none;
    padding-right: 20px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 150px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 320px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: auto;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: none;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
    display: none;
  }
  .header_searchIcon0 {
    display: none;
  }

  .header_searchIcon3 {
    display: block;
  }
  .header_searchInput {
    width: 158px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    /* display: none; */
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  .sep {
    display: none;
    padding-left: 5px;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: none;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: none;
    padding-right: 20px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 180px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 375px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: auto;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: none;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: none;
  }
  .header_searchIcon3 {
    display: block;
  }
  .header_searchInput {
    width: 178px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    /* display: none; */
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  .sep {
    display: none;
    padding-left: 5px;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: none;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: none;
    padding-right: 10px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 250px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 425px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: auto;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: none;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
    display: none;
  }
  .header_searchIcon0 {
    display: none;
  }
  .header_searchIcon3 {
    display: block;
  }
  .header_searchInput {
    width: 198px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    /* display: none; */
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  .sep {
    display: none;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: none;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: none;
    padding-right: 20px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 300px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 768px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: 100px;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: block;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: block;
  }
  .header_searchIcon3 {
    display: none;
  }
  .header_searchInput {
    width: 198px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    display: block;
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* width: 230px; */
    width: auto;
    padding-right: 20px;
  }
  .sep {
    display: block;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: block;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: block;
    padding-right: 0px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .searchResultShow {
    width: 600px;
    background-color: azure;
    color: black;
    position: absolute;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: hidden;
    padding: 10px 5px;
  }
  .searchResultShow_01 {
    margin-bottom: 5px;
    padding: 2px;
  }
  .searchResultShow_01:hover {
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: 100px;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: block;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: block;
  }
  .header_searchIcon3 {
    display: none;
  }

  .header_searchInput {
    width: 198px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    display: block;
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* width: 230px; */
    width: auto;
    padding-right: 25px;
  }
  .sep {
    display: block;
    padding-left: 10px;
  }

  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: block;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: block;
    padding-right: 0px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
}
@media only screen and (min-width: 1200px) {
  .headerContainer {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    flex: 1;
    width: 100%;
  }

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .header_menu {
    width: 100px;
    object-fit: contain;
    margin: 0 20px;
  }

  .header_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: -5px;
    display: block;
  }

  .header_menuText {
    margin-left: -70px;
  }

  .header_search {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .header_searchIcon {
    width: 23px;
    height: 23px;
    background-color: none;
  }
  .header_searchIcon0 {
    display: block;
  }
  .header_searchIcon3 {
    display: none;
  }
  .header_searchInput {
    width: 198px;
    height: 28px;
    border: none;
    border-radius: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    text-align: center;
    display: block;
  }

  .headerLast {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding-right: 25px;
  }
  .sep {
    display: block;
    padding-left: 10px;
  }
  .header_login {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: block;
  }

  .header_register {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    text-align: center;
    display: block;
    padding-right: 0px;
  }

  .header_logo {
    margin-right: 50px;
  }

  .header_logo > .accountCircleIcon {
    width: 30px;
    height: 30px;
    margin-right: 0px;
    margin-top: 0px;
  }
}
