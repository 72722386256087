.login {
  background: rgba(0, 0, 0, 0.41);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 1870px 1448px;
  position: absolute;
}

.login__container {
  box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
  width: 390px;
  height: 495px;
  background: #FFFFFF;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.loginTopic {
  font-family: 'Montserrat';
font-style: normal;
font-weight: bold;
font-size: 25.4086px;
line-height: 31px;
color: #000000;
margin-top: 20px;
margin-bottom: 20px;
}
.login__container > form {
  padding: 20px;
}
.inputText {
  color: #000000;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;  
}

.login__container > form > input,
.bx > input {
  padding-left: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 271px;
  height: 41px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px; 
  background: #E5E5E5;
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.1);
}

.para1{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  text-decoration: none;
  color: #000000;
  text-align: left;
}

.checkboxContainer {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}

.checkboxContainer input {
  width: 9px;
  height: 9px;
  border: 0.5px solid #868686;
  box-sizing: border-box;
  border-radius: 1px;
}

.login__container > form > button {
  width: 271px;
  height: 34px;
  border: 1px solid #830000;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 6px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #830000;
}

.paragraph {
  display: flex;
  flex-direction:row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.para2{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 10.6917px;
  line-height: 13px;
  color: #000000;
  text-align: center;
  text-decoration: none;
}

.para3{
  cursor: pointer;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 10.6917px;
  line-height: 13px;
  color: maroon;
  text-decoration: none;
}

i {
  position: absolute;
}

.loginRespectLogoRed {
  width: 68px;
  height: 27px;
}

.login_close {
  /* margin-left: 310px;
  font-size: 30px;
  margin-top: -15px; */
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
}
@media only screen and (min-width: 280px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 250px;
    height: 57vh;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 10px;
  }
  .login__container > form {
    padding: 10px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 1;
    width: 180px;
    height: 28px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 8px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 180px;
    height: 28px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
    cursor: pointer;
    
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  i {
    position: absolute;
    margin-top: -15px;
    margin-right: 10px;
  }
  i >.svg-inline--fa{
    font-size: 10px;
  }
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    font-size: 20px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 320px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 300px;
    height: 440px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 240px;
    height: 38px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 8px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 240px;
    height: 32px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  i >.svg-inline--fa{
    font-size: 12px;
  }
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    font-size: 20px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 375px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 340px;
    height: 480px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 270px;
    height: 41px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 8px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 270px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    font-size: 20px;
    cursor: pointer;
  }
}
@media only screen and (min-width: 425px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 380px;
    height: 505px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 290px;
    height: 42px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 8px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 290px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    font-size: 25px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }
}
@media only screen and (min-width: 768px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 460px;
    height: 565px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 320px;
    height: 42px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 20px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 320px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
   font-size: 25px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1024px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 460px;
    height: 565px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 320px;
    height: 42px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 20px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 320px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
   font-size: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1200px) {
  .login {
    background: rgba(0, 0, 0, 0.41);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 1870px 1448px;
    position: absolute;
  }
  
  .login__container {
    box-shadow: 6px 7px 18px rgba(0, 0, 0, 0.25);
    width: 460px;
    height: 565px;
    background: #FFFFFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .loginTopic {
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25.4086px;
  line-height: 31px;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 20px;
  }
  .login__container > form {
    padding: 20px;
  }
  .inputText {
    color: #000000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    text-align: left;
    width: 100%;
  }
  
  .login__container > form > input,
  .bx > input {
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 320px;
    height: 42px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; 
    background: #E5E5E5;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.1);
  }
  .para1{
    width: 100%;
    text-align: left;
    padding: 2px 0px;
  }
  .para1 > span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    text-decoration: none;
    color: #000000;
    text-align: left;
  }
  
  .checkboxContainer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:center;
    text-align: left;
    padding-bottom: 20px;
  }
  
  .checkboxContainer input {
    width: 15px;
    height: 15px;
    border: 0.5px solid #868686;
    box-sizing: border-box;
    border-radius: 1px;
  }
  
  .login__container > form > button {
    width: 320px;
    height: 35px;
    border: 1px solid #830000;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #830000;
  }
  .login__container > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paragraph {
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .para2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 10.6917px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }
  
  .para3{
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 10.6917px;
    line-height: 13px;
    color: maroon;
    text-decoration: none;
  }
  .bx{
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  i {
    position: absolute;
    margin-top: -10px;
    margin-right: 10px;
  }
  
  .loginRespectLogoRed {
    width: 68px;
    height: 27px;
  }
  
  .login_close {
    font-size: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
  }
}