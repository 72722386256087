.upperTextContainer {
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: flex-start;
}

.containerText,
.containerText1 {
  display: flex;
  flex-direction: column;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 11.9912px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: -0.015em;
  color: #000000;
  width: auto;
}
@media only screen and (min-width: 320px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
  }
}
@media only screen and (min-width: 375px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
  }
}
@media only screen and (min-width: 425px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
  }
}
@media only screen and (min-width: 768px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    flex: 1;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1200px) {
  .upperTextContainer {
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    flex: 1;
    align-items: flex-start;
  }

  .containerText,
  .containerText1 {
    display: flex;
    flex-direction: column;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    /* line-height: 18px; */
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #000000;
    width: auto;
    line-height: 1.5;
  }
}
