.bodyLeftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 30px 0px 0px 0px;
}
.topicAndImgLeftContainer {
  display: flex;
  flex: 0.5;
  flex-direction: column;
}
.topicLeftContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 5px 0px;
}
.topicLeftContainer > span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
}
.img__box {
  height: 230px;
  overflow: hidden;
}
.image_leftContainer {
  width: 230px;
  height: 230px;
}
.imageContainer {
  display: flex;
  flex-direction: row;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.015em;
  width: auto;
}

.textContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}
.textContainer:hover {
  cursor: pointer;
  color: #c4c4c4;
}
.left_containerHeading {
  padding-top: 5px;
}
.left_containerHeading > span {
  font-family: "";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-decoration: underline;

}
.left_containerContent {
  padding: 10px 5px 0px 0px;
  text-align: justify;
}
.left_containerContent > span {
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.015em;
}
@media only screen and (min-width: 280px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px 10px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 85%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 310;
    font-size: 13px;
  }
  .img__box {
    height: 200px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: 100%;
    object-fit: cover;
    height: 240px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 1;
    width: 100%;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 10px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12.5px;
    line-height: 9px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 320px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px 10px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 85%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 310;
    font-size: 13px;
  }
  .img__box {
    height: 200px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: 100%;
    object-fit: cover;
    height: 240px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 1;
    width: 100%;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    /* text-transform: capitalize; */
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 10px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12.5px;
    line-height: 9px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 375px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 20px 10px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    width: 172px;
    overflow: hidden;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    flex: 0.5;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 310;
    font-size: 13px;
  }
  .img__box {
    height: 300px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 0.5;
    width: 100%;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    /* text-transform: capitalize; */
    text-decoration: underline;
    

  }
  .left_containerContent {
    padding: 10px 5px 10px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 9px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 425px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 20px 20px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    width: 172px;
    overflow: hidden;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 300px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 0.6;
    width: 100%;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;
    

  }
  .left_containerContent {
    padding: 10px 5px 20px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 540px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 20px 20px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    width: 172px;
    overflow: hidden;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 230px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: auto;
    object-fit: cover;
    height: 230px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0px;
    flex: 0.6;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 20px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 768px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 20px 20px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    width: 172px;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 210px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: auto;
    object-fit: cover;
    height: 210px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px 30px 0px;
    flex: 1;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 30px 0px;
    text-align: justify;
    border-bottom: 1px solid #e6e6e6;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.015em;
  }
}

@media only screen and (min-width: 1024px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 35px 0px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    width: 182px;
    overflow: hidden;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 270px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: auto;
    object-fit: cover;
    height: 210px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;

  }
  .left_containerContent {
    padding: 10px 5px 0px 0px;
    text-align: justify;
    border-bottom: none;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
  }
}
@media only screen and (min-width: 1200px) {
  .bodyLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 30px 0px 0px 0px;
  }
  .topicAndImgLeftContainer {
    display: flex;
    flex: 0.5;
    flex-direction: column;
  }
  .topicLeftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 0px;
  }
  .topicLeftContainer > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
  }
  .img__box {
    height: 225px;
    overflow: hidden;
  }
  .image_leftContainer {
    width: auto;
    height: 225px;
  }
  .imageContainer {
    display: flex;
    flex-direction: row;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.015em;
    width: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
  }
  .left_containerHeading {
    padding-top: 5px;
  }
  .left_containerHeading > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.5;
    /* text-transform: capitalize; */
    text-decoration: underline;
  }
  .left_containerContent {
    padding: 10px 5px 0px 0px;
    text-align: justify;
    border-bottom: none;
  }
  .left_containerContent > span {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
  }
}

.left_container {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.hr_lineLeft {
  width: auto;
  display: none;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  border: 1px solid #e6e6e6;
  margin-bottom: -20px;
}
