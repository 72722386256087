.home_bodyRight {
  display: flex;
  flex: 0.53;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
}
.bodyContentRow_heading {
  display: block;
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 0px 0px 30px;
}
.bodyContentRow_heading > span {
  display: block;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  text-align: left;
  line-height: 1.5;
}
@media only screen and (min-width: 280px) {
  .home_bodyRight {
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}
@media only screen and (min-width: 320px) {
  .home_bodyRight {
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}
@media only screen and (min-width: 375px) {
  .home_bodyRight {
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}
@media only screen and (min-width: 425px) {
  .home_bodyRight {
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}
@media only screen and (min-width: 768px) {
  .home_bodyRight {
    display: flex;
    flex: 1;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
  .bodyContentRow_heading {
    display: none;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 00px;
  }
  .bodyContentRow_heading > span {
    display: none;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}

@media only screen and (min-width: 1024px) {
  .home_bodyRight {
    display: flex;
    flex: 0.53;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
  .bodyContentRow_heading {
    display: block;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 30px;
  }
  .bodyContentRow_heading > span {
    display: block;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: left;
    line-height: 54px;
  }
}

@media only screen and (min-width: 1200px) {
  .home_bodyRight {
    display: flex;
    flex: 0.54;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
  .bodyContentRow_heading {
    display: block;
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 30px;
  }
  .bodyContentRow_heading > span {
    display: block;
    font-family:arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    text-align: left;
    line-height: 1.5;
  }
}
