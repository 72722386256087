.home_bodyLeft {
  display: flex;
  flex: 0.47;
  flex-direction: column;
}

.BodyContent_margin {
  display: block;
  margin-right: 20px;
  padding-top: 20px;
}
@media only screen and (min-width: 280px) {
  .home_bodyLeft {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 320px) {
  .home_bodyLeft {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 375px) {
  .home_bodyLeft {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 425px) {
  .home_bodyLeft {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 10px;
  }
}
@media only screen and (min-width: 678px) {
  .home_bodyLeft {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .BodyContent_margin {
    display: none;
    margin-right: 20px;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .home_bodyLeft {
    display: flex;
    flex: 0.47;
    flex-direction: column;
  }

  .BodyContent_margin {
    display: block;
    margin-right: 20px;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .home_bodyLeft {
    display: flex;
    flex: 0.46;
    flex-direction: column;
    /* align-items: flex-start; */
  }

  .BodyContent_margin {
    display: block;
    margin-right: 20px;
    padding-top: 20px;
    flex:1;
    height: 40vh;
    overflow: auto;
  }
}
