.categoryUpper {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 30px;
}

.mainUpperContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.categoryUp {
  display: flex;
  flex-direction: column;
}

.categoryLine {
  width: auto;
  height: 4px;
  background-color: maroon;
}

.categoryHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 29.6px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #000000;
}

.categoryText {
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #404040;
}

.categoryAddition select {
  width: 210px;
  padding: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #c4c4c4;
  border: none;
  height: 38px;
}

.categoryAddition {
  width: 230px;
  height: 38px;
  background: #e0e0e0;
  border-radius: 5px;
  border: none;
}

.imageCContainer {
  display: flex;
  flex-direction: column;
  width: auto;
  overflow: hidden;
}

.categoryImage1 {
  width: auto;
  height: 875px;
  padding: 20px 0px;
}

.upperContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8vh;
}

.upperHeading {
  font-family: "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22.1377px;
  line-height: 33px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
  width: 750px;
  /* text-transform: capitalize; */
}

.topicUpperContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #454545;
}

.dateUpperContainer {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #454545;
  padding-bottom: 10px;
}
.box {
  border: 1px solid #c4c4c4;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.UCParagaraph {
  display: flex;
  flex-direction: row;
  width: auto;
}

.upperContainerButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.buttonReadMore {
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 11.9912px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: -0.015em;
  color: #151617;
  width: 136px;
  height: 30px;
  justify-content: center;
  background-color: white;
  align-items: center;
}
.buttonReadMore:hover {
  cursor: pointer;
  color: #c4c4c4;
  border-color: #c4c4c4;
}
@media only screen and (min-width: 320px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px 30px 10px 30px;
  }

  .mainUpperContainer {
    flex-wrap: wrap;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
    padding: 10px 0px 0px;
  }

  .categoryAddition select {
    width: 180px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 28px;
  }

  .categoryAddition {
    width: 180px;
    height: 28px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 220px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 250px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 120px;
    height: 30px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 375px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px 30px 10px 30px;
  }

  .mainUpperContainer {
    flex-wrap: wrap;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
    padding: 10px 0px;
  }

  .categoryAddition select {
    width: 180px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 28px;
  }

  .categoryAddition {
    width: 180px;
    height: 28px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 260px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 320px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 100px;
    height: 30px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 425px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px 30px 10px 30px;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
  }

  .categoryAddition select {
    width: 180px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 38px;
  }

  .categoryAddition {
    width: 180px;
    height: 38px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 280px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 320px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 120px;
    height: 35px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 768px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
  }

  .categoryAddition select {
    width: 210px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 38px;
  }

  .categoryAddition {
    width: 230px;
    height: 38px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 480px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 650px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 130px;
    height: 37px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 1024px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 29.6px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
  }

  .categoryAddition select {
    width: 210px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 38px;
  }

  .categoryAddition {
    width: 230px;
    height: 38px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 690px;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22.1377px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 750px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 134px;
    height: 35px;
    justify-content: center;
    background-color: white;
    align-items: center;
    vertical-align: middle;
  }
}
@media only screen and (min-width: 1200px) {
  .categoryUpper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px;
  }

  .categoryUp {
    display: flex;
    flex-direction: column;
  }

  .categoryLine {
    width: auto;
    height: 4px;
    background-color: maroon;
  }

  .categoryHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 29.6px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .categoryText {
    display: flex;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #404040;
  }

  .categoryAddition select {
    width: 210px;
    padding: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #c4c4c4;
    border: none;
    height: 38px;
  }

  .categoryAddition {
    width: 230px;
    height: 38px;
    background: #e0e0e0;
    border-radius: 5px;
    border: none;
  }

  .imageCContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    overflow: hidden;
  }

  .categoryImage1 {
    width: auto;
    height: 875px;
    object-fit: fill;
    padding: 20px 0px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
  }

  .upperHeading {
    font-family: "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    width: 850px;
    /* text-transform: capitalize; */
  }

  .topicUpperContainer {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
  }

  .dateUpperContainer {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #454545;
    padding-bottom: 10px;
  }
  .box {
    border: 1px solid #c4c4c4;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .UCParagaraph {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .upperContainerButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .buttonReadMore {
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    color: #151617;
    width: 140px;
    height: 41px;
    justify-content: center;
    background-color: white;
    align-items: center;
  }
  .pageNotFound {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px 0;
    font-style: italic;
    color:#706f6f
    
  }
}
