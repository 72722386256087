.atclcontainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: white;
}
.imgAndText {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.imageDiv {
  overflow: hidden;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: solid;
}

.gradient {

  z-index: 1;
  width: 100%;
  height: 150px;
}
.containerAd__para {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-bottom: 10px;
}
.container_para {
  flex: 1;
  /* padding-right: 30px; */
}
.ad_div {
  display: flex;
  flex: 0.17;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 65px 10px 40px 0px;
  display: block;
}
.ads {
  width: 90%;
  height: 100%;
  background: #c4c4c4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.uploaded__Ad {
  width: 100%;
  background: #c4c4c4;
  display: flex;
  object-fit: cover;
}
.ads > input {
  visibility: hidden;
  width: 0;
  height: 0;
  cursor: pointer;
}
.ads > i {
  font-family: "arial, sans-serif";
  font-style: normal;
  font-weight: 300;
  font-size: 29.6111px;
  line-height: 44px;
  letter-spacing: -0.015em;
  color: #000000;
}
.para {
  font-family: "arial, sans-serif";
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: -0.015em;
  /* padding: 10px 0px 30px 30px; */
  color: #000000;
}
.para_heading {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  margin-top: 20px;
  color: #454545;
  padding-left: 30px;
}
.para_date {
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  padding-left: 30px;
  color: #454545;
  /* text-transform: capitalize; */
}
.image_text {
  font-style: normal;
  font-family: "arial, sans-serif";
  font-weight: bold;
  font-size: 36.1377px;
  line-height: 54px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color:black;
  position: absolute;
  width: 100%;
  padding: 30px;
  /* z-index: 1; */
}

.image_2nd {
  width: 100%;
  height: 600px;
  object-fit: contain;
}
.image_2ndDiv {
  overflow: hidden;
  width: auto;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
}
.horizontal_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-bottom: 20px;
  overflow-x: scroll;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  z-index: 1;
}
.more_art {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
  padding-left: 0px;
  padding-top: 10px;
}
.hr_article {
  display: flex;
  flex-direction: row;
}
@media only screen and (min-width: 320px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }

  .gradient {
    
    z-index: 1;
    width: 100%;
    height: 60px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-bottom: 10px;
  }
  .container_para {
    flex: 1;
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: none;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 20px; */
    color: #000000;
  }
  .para_heading {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 20px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 20px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 100%;
    padding: 20px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 180px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
    padding-left: 20px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 8px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 375px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }

  .gradient {
    z-index: 1;
    width: 100%;
    height: 100px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-bottom: 10px;
  }
  .container_para {
    flex: 1;
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: none;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }
  .para_heading {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 100%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 220px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
    padding-left: 30px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 8px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 425px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }
 
  .gradient {
   
    z-index: 1;
 
    width: 100%;
    height: 200px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-bottom: 10px;
  }
  .container_para {
    flex: 1;
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: none;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }

  .para_heading {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 100%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 250px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
    padding-left: 30px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 8px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 768px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }
 
  .gradient {
   
    z-index: 1;
 
    width: 100%;
    height: 150px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-bottom: 10px;
  }
  .container_para {
    /* flex: 0.83; */
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: block;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }
  .para_heading {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 34px;
    line-height: 44px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    position: absolute;
    color:black;
    width: 100%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 390px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 10px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1024px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }
 
  .gradient {
   
    z-index: 1;
 
    width: 100%;
    height: 150px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-bottom: 10px;
  }
  .container_para {
    /* flex: 0.83; */
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: block;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }
  .para_heading {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 35px;
    line-height: 54px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 95%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 100%;
    height: 500px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 10px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1200px) {
  .atclcontainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
  }
  .imgAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .imageDiv {
    overflow: hidden;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom: solid;
  }
 

  .gradient {
   
    z-index: 1;
 
    width: 100%;
    height: 150px;
  }
  .containerAd__para {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-bottom: 10px;
  }
  .container_para {
    flex: 1;
    /* padding-right: 30px; */
  }
  .ad_div {
    display: flex;
    flex: 0.17;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px 0px;
    display: block;
  }
  .ads {
    width: 90%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .uploaded__Ad {
    width: 100%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    object-fit: cover;
  }
  .ads > input {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
  }
  .ads > i {
    font-family: "arial, sans-serif";
    font-style: normal;
    font-weight: 300;
    font-size: 29.6111px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .para {
    font-family: "arial, sans-serif";
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.015em;
    /* padding: 10px 0px 30px 30px; */
    color: #000000;
  }

  .para_heading {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-top: 20px;
    color: #454545;
    padding-left: 30px;
  }
  .para_date {
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    padding-left: 30px;
    color: #454545;
  }
  .image_text {
    font-style: normal;
    font-family: "arial, sans-serif";
    font-weight: bold;
    font-size: 36.1377px;
    line-height: 54px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color:black;
    position: absolute;
    width: 100%;
    padding: 30px;
    /* z-index: 1; */
  }

  .image_2nd {
    width: 75%;
    height: 600px;
  }
  .image_2ndDiv {
    overflow: hidden;
    width: auto;
    padding-bottom: 5px;
  }
  .horizontal_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
    overflow-x: scroll;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
  .more_art {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #000000;
    padding-left: 0px;
    padding-top: 10px;
  }
  .hr_article {
    display: flex;
    flex-direction: row;
  }
}
