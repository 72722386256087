.body_content {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
}

.headingBodyContent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  width: auto;
  text-decoration: underline;
  /* text-transform: capitalize; */
}

.contentOfBodyContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
  flex: 1;
}

.content_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  flex: 1;
  padding: 0px 0px;
  text-align: justify;
}

.content_image {
  width: 233px;
  height: 176px;
  display: block;
}

.topicBodyContent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 12pt;
  line-height: 16px;
  letter-spacing: -0.015em;
  margin-left: 0px;
}

.buttonLearnMore {
  background: #ffffff;
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  width: 130px;
  height: 40px;
  border: 1px solid #555;
  cursor: pointer;
  width: 120px;
  height: 40px;
  margin-top: 15px;
  margin-left: 0px;
  letter-spacing: -0.015em;
  color: #151617;
  margin-bottom: 20px;
}
@media only screen and (min-width: 280px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px;
    flex: 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    border: 1px solid #555;
    cursor: pointer;
    width: 70px;
    height: 20px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 320px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px;
    flex: 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    border: 1px solid #555;
    cursor: pointer;
    width: 70px;
    height: 20px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 375px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px;
    flex: 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 425px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px;
    flex: 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    width: 85%;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    padding: 0px 5px;
    flex: 1;
  }
  .imgContainer__box {
    display: none;
    width: 203px;
    height: 176px;
    object-fit: cover;
    overflow: hidden;
  }
  .content_image {
    display: none;
    width: auto;
    height: 176px;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    width: 49%;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    width: auto;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding: 0px 5px;
    flex: 1;
  }
  .imgContainer__box {
    width: 203px;
    height: 176px;
    object-fit: cover;
    display: block;
    overflow: hidden;
  }
  .content_image {
    width: auto;
    height: 176px;
    display: block;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .body_content {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    width: 49%;
    justify-content: space-between;
  }

  .headingBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    width: auto;
    text-decoration: underline;
    /* text-transform: capitalize; */
  }

  .contentOfBodyContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    flex: 1;
  }

  .content_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    padding: 0px 5px;
    flex: 1;
  }
  .imgContainer__box {
    width: 203px;
    height: 176px;
    object-fit: cover;
    display: block;
    overflow: hidden;
  }
  .content_image {
    width: auto;
    height: 176px;
    display: block;
  }

  .topicBodyContent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 12pt;
    line-height: 16px;
    letter-spacing: -0.015em;
    margin-left: 0px;
  }

  .buttonLearnMore {
    background: #ffffff;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: 1px solid #555;
    cursor: pointer;
    width: 120px;
    height: 40px;
    margin-top: 15px;
    margin-left: 0px;
    letter-spacing: -0.015em;
    color: #151617;
    margin-bottom: 20px;
  }
}
