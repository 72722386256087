
.Navbar {
  width: auto;
}

.home_container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #71888e;
  width: 100%;
  height: 304px;
}
.home_log {
  width: 365px;
  height: 145px;
  object-fit: contain;

}

.home_hContent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
  padding: 20px;
}
.content_h {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  padding-left: 50px;
  width: 100%;
}

.home_date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
}
@media only screen and (min-width:280px) {
  .Navbar {
      width: auto;
    }
    
    .home_container {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #71888e;
      width: 100%;
      height: 250px;
    }
    .home_log {
      width: 190px;
      height: 60px;
      object-fit: contain;

    }
    
    .home_hContent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
      padding: 10px 20px;
    }
    .content_h {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      padding-left: 20px;
      padding-top: 40px;
      width: 100%;
    }
    
    .home_date {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
    }
}
@media only screen and (min-width:320px) {
  .Navbar {
      width: auto;
    }
    
    .home_container {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #71888e;
      width: 100%;
      height: 250px;
    }
    .home_log {
      width: 190px;
      height: 60px;
      object-fit: contain;

    }
    
    .home_hContent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
      padding: 10px 20px;
    }
    .content_h {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      padding-left: 20px;
      padding-top: 40px;
      width: 100%;
    }
    
    .home_date {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
    }
}
@media only screen and (min-width:375px) {
  .Navbar {
      width: auto;
    }
    
    .home_container {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #71888e;
      width: 100%;
      height: 260px;
    }
    .home_log {
      width: 230px;
      height: 80px;
      object-fit: contain;

    }
    
    .home_hContent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
      padding: 20px 20px;
    }
    .content_h {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      padding-left: 20px;
      padding-top: 20px;
      width: 100%;
    }
    
    .home_date {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
    }
}
@media only screen and (min-width:425px) {
  .Navbar {
      width: auto;
    }
    
    .home_container {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #71888e;
      width: 100%;
      height: 270px;
    }
    .home_log {
      width: 230px;
      height: 80px;
      object-fit: contain;

    }
    
    .home_hContent {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
      padding: 20px;
    }
    .content_h {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      vertical-align: middle;
      padding-left: 20px;
      padding-top: 30px;
      width: 100%;
    }
    
    .home_date {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
    }
}
@media only screen and (min-width:768px) {
    .Navbar {
        width: auto;
      }
      
      .home_container {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #71888e;
        width: 100%;
        height: 290px;
      }
      .home_log {
        width: 305px;
        height: 115px;
        object-fit: contain;

      }
      
      .home_hContent {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
        padding: 20px;
      }
      .content_h {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        vertical-align: middle;
        padding-left: 20px;
        padding-top: 20px;
        width: 100%;
      }
      
      .home_date {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
      }
}
@media only screen and (min-width:1024px) {
    .Navbar {
        width: auto;
      }
      
      .home_container {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #71888e;
        width: 100%;
        height: 304px;
      }
      .home_log {
        width: 355px;
        height: 135px;
        object-fit: contain;

      }

      
      .home_hContent {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
        padding: 20px;
      }
      .content_h {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        vertical-align: middle;
        padding-left: 20px;
        padding-top: 20px;
        width: 100%;
      }
      
      .home_date {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
      }
}
@media only screen and (min-width:1200px) {
    .Navbar {
        width: auto;
      }
      
      .home_container {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #71888e;
        width: 100%;
        height: 304px;
      }
      .home_log {
        width: 365px;
        height: 145px;
        object-fit: contain;
      }
      
      .home_hContent {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
        padding: 20px;
      }
      .content_h {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        vertical-align: middle;
        padding-left: 20px;
        width: 100%;
      }
      
      .home_date {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.015em;
        color: #ffffff;
      }
}
/*

{
a:nth-child(2):hover ~ .line {
    transform: translate(126px);
}

a:nth-child(3):hover ~ .line {
    transform: translate(252px);
}

a:nth-child(4):hover ~ .line {
    transform: translate(378px);
}

a:nth-child(5):hover ~ .line {
    transform: translate(504px);
}

a:nth-child(6):hover ~ .line {
    transform: translate(630px);
}

a:nth-child(7):hover ~ .line {
    transform: translate(756px);
}

a:nth-child(8):hover ~ .line {
    transform: translate(882px);
}

a:nth-child(9):hover ~ .line {
    transform: translate(1008px);
}

a:nth-child(10):hover ~ .line {
    transform: translate(1134px);
}

a:nth-child(11):hover ~ .line {
    transform: translate(1260px);
}

a:nth-child(12):hover ~ .line {
    transform: translate(1386px);
}

a:nth-child(13):hover ~ .line {
    transform: translate(1512px);
}

a:nth-child(14):hover ~ .line {
    transform: translate(1680px);
}}*/
