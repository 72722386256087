.homeFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 0px;
}

.footer1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  height: 200px;
  width: 100%;
  padding: 0px 50px;
  background-color: #e0e0e0;
}

.respectLogoRed {
  width: 222px;
  height: 91px;
}

.footer {
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}
.footer_content1 > span,
.footer_content2 > span {
  padding-bottom: 10px;
  font-weight: 900;
}
.footer_content1 {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
  padding-bottom: 0px;
}

.footer_content2 {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
  padding-bottom: 0px;
}

.footer_content3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: auto;
}

.button8 {
  background: maroon;
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  width: 130px;
  height: 40px;
  border: none;
  cursor: pointer;
  color: white;
}

.button9 {
  background: white;
  border: 1px solid #151617;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  width: 130px;
  height: 40px;
  border: none;
  cursor: pointer;
  color: black;
  margin-top: 20px;
  border: 1px solid #555;
}

.footer_last {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}

.footer_last1 {
  display: flex;
  flex-direction: row;
  color: black;
  font-size: 20px;
  margin-left: 40px;
  width: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
}

.footer_last {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.footer_last1,
.footer_last2 {
  width: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
}
.footer_link {
  text-decoration: none;
  color: black;
}
.smedia_icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0px;
}
.smedia_icon_1 {
  margin-right: 5px;
}
.contact_us {
  /* font-weight: bold; */
  margin: 10px 0px;
}
@media only screen and (min-width: 280px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: fit-content;
    width: 100%;
    padding: 20px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 160px;
    height: 58px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-around;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
    align-items: center;
  }
  .smedia_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: center;
  }

  .footer_content3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    vertical-align: middle;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 90px;
    height: 35px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 90px;
    height: 35px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 0px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 0px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
}
@media only screen and (min-width: 320px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: fit-content;
    width: 100%;
    padding: 20px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 160px;
    height: 58px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-around;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
    align-items: center;
  }
  .smedia_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: center;
  }

  .footer_content3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    vertical-align: middle;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 90px;
    height: 35px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 90px;
    height: 35px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 0px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 0px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
}
@media only screen and (min-width: 375px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: fit-content;
    width: 100%;
    padding: 20px 50px 20px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 170px;
    height: 60px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-around;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
    align-items: center;
  }
  .smedia_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: center;
  }

  .footer_content3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    vertical-align: middle;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 110px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 110px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 0px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
}
@media only screen and (min-width: 425px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: fit-content;

    width: 100%;
    padding: 15px 50px 0px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 180px;
    height: 70px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-around;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 10px;
    align-items: center;
  }
  .smedia_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: center;
  }

  .footer_content3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    vertical-align: middle;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 110px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 110px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 0px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
}
@media only screen and (min-width: 768px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    height: 160px;
    width: 100%;
    padding: 0px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 190px;
    height: 80px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    width: 52%;
    justify-content: space-between;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 0px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
    align-items: flex-start;
  }

  .footer_content3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: auto;
    width: auto;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 120px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 120px;
    height: 38px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 5px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
  .smedia_icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: left;
  }
}
@media only screen and (min-width: 1024px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    height: 180px;
    width: 100%;
    padding: 0px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 220px;
    height: 90px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    width: 42%;
    justify-content: space-between;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 5px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
    align-items: flex-start;
  }

  .footer_content3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: auto;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 125px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 125px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 10px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
  .smedia_icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: left;
  }
}
@media only screen and (min-width: 1200px) {
  .homeFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }

  .footer1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    height: 200px;
    width: 100%;
    padding: 0px 50px;
    background-color: #e0e0e0;
  }

  .respectLogoRed {
    width: 222px;
    height: 91px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-between;
    align-items: center;
  }
  .footer_content1 > span,
  .footer_content2 > span {
    padding-bottom: 5px;
  }
  .footer_content1 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
  }

  .footer_content2 {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    padding-bottom: 0px;
    align-items: flex-start;
  }

  .footer_content3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: auto;
  }

  .button8 {
    background: maroon;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: white;
  }

  .button9 {
    background: white;
    border: 1px solid #151617;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    width: 130px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: black;
    margin-top: 10px;
    border: 1px solid #555;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  .footer_last1 {
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 20px;
    margin-left: 40px;
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .footer_last1,
  .footer_last2 {
    width: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
  }
  .footer_link {
    text-decoration: none;
    color: black;
  }
  .smedia_icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0px;
  }
  .smedia_icon_1 {
    margin-right: 5px;
  }
  .contact_us {
    /* font-weight: bold; */
    margin: 10px 0px;
    text-align: left;
  }
}
