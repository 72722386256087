.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
}
.body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 0.5;
}
@media only screen and (min-width: 320px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
}
@media only screen and (min-width: 375px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
}
@media only screen and (min-width: 425px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
}
@media only screen and (min-width: 768px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80vh;
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
}
@media only screen and (min-width: 1024px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0.5;
  }
}
@media only screen and (min-width: 1200px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0.5;

  }
}
